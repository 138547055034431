import React, { useEffect, useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MdxLink } from 'gatsby-theme-i18n';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import Header from './Header';
import HeaderLight from './HeaderLight';
import Footer from './Footer';
import ScrollToTop from '../ScrollToTop';
import Banner from '../Banner';
import MDXButton from '../MDXButton';

interface LayoutProps {
  children: React.ReactNode;
  theme?: string;
}

const components = {
  a: MdxLink,
  MDXButton,
};

export default function Layout(props: LayoutProps) {
  const location = useLocation();
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    // Para a carga inicial, uso um delay antes de marcar o conteúdo como carregado (Para twitter não aparecer antes)
    const timer = setTimeout(() => setIsContentLoaded(true), 100); // Delay conforme necessário

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const acceptCookieButton = document.querySelector('.cm-btn-success');
    
    acceptCookieButton?.addEventListener('click', () => {
      initializeAndTrack(location);
    });
  }, [location]);

  // Estilo de visibilidade baseado no estado isContentLoaded
  useEffect(() => {
    document.body.style.visibility = isContentLoaded ? 'visible' : 'hidden';
  }, [isContentLoaded]);

  return (
    <>
      {isContentLoaded && (
        <>
          <Banner />
          {props.theme === 'light' ? <HeaderLight /> : <Header />}
          <main>
            <ScrollToTop />
            <MDXProvider components={components}>{props.children}</MDXProvider>
          </main>
          <Footer />
        </>
      )}
    </>
  );
}